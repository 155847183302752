import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { Dimensions } from 'react-native';

// Create a new context
const AppContext = createContext();

// Use the AppContext in your components
export const useAppContext = () => useContext(AppContext);

// Create a provider component
export const AppProvider = ({ children }) => {
    // Define your state variables here
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const isLargeScreenRef = useRef(isLargeScreen);

    useEffect(() => {
        const updateScreenSize = () => {
            const { width, height } = Dimensions.get('window');
            const isWide = width >= 500;
            const isTall = height >= 500;
            const isLarge = isWide && isTall;
            setIsLargeScreen(isLarge);
            isLargeScreenRef.current = isLarge;
        };

        updateScreenSize(); // Check initial size
        Dimensions.addEventListener('change', updateScreenSize); // Listen for changes

        return () => {
            Dimensions.removeEventListener('change', updateScreenSize); // Clean up listener
        };
    }, []);

    // Return the provider component with the context value
    return (
        <AppContext.Provider
            value={{
                isLoggedIn,
                setIsLoggedIn,
                user,
                setUser,
                isLargeScreen: isLargeScreenRef.current,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};