import React, { useMemo, useState } from 'react';
import { View, StyleSheet, useColorScheme, Pressable, Alert } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import LargeSeriesScreen from '../screens/LargeSeriesScreen';
import { SeriesProvider, useSeriesContext } from '../context/SeriesContext'; // Import useSeriesContext
import { lightTheme, darkTheme } from '../styles/theme';
import { TouchableRipple, Tooltip, PaperProvider } from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';
import { Share, Linking } from 'react-native';
import { useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native'; // Updated imports
import { devLog } from '../utils/utilities';

const LargeScreenNavigator = () => {
  // console.log('LargeScreenNavigator');
  const colorScheme = useColorScheme();
  const theme = useMemo(() => (colorScheme === 'dark' ? darkTheme : lightTheme), [colorScheme]);

  const { tagListVisible, setTagListVisible, selectedSeries, selectedSeriesList, setSelectedSeriesList, numYearsToDisplay, setNumYearsToDisplay, updateSelectedSeriesById, seriesData } = useSeriesContext();

  const navigation = useNavigation();
  const route = useRoute();

  // On series selection, navigate to the series screen with the selected series id
  useEffect(() => {
    // console.log('EFFECT - Selected series:', selectedSeries);
    if (selectedSeries && selectedSeries.id) {
      navigation.navigate('Series', { id: selectedSeries.id });
    }
  }, [selectedSeries]);

  // On route change, fetch the selected series by id
  useEffect(() => {
    // console.log('EFFECT - Route params:', route.params);
    const seriesId = route.params?.id;
    if (seriesId === 'undefined' || seriesId === undefined) {
      // console.log('Series id not found in route');
    } else {
      updateSelectedSeriesById(seriesId);
    }
  }, [route.params?.id]);

  const toggleTagList = () => {
    setTagListVisible(!tagListVisible);
  };
  const selectSeriesListTag = () => {
    setSelectedSeriesList('tag');
  }
  const selectSeriesListFavorites = () => {
    setSelectedSeriesList('favs');
  }
  const onMenuPress = () => {
    console.log('Menu pressed');
  };

  // useEffect(() => {
  //   alert("This product uses the FRED® API but is not endorsed or certified by the Federal Reserve Bank of St. Louis.");
  // }, []);

  const openSeriesLink = () => {
    const url = `https://fred.stlouisfed.org/series/${selectedSeries.id}`;
    Linking.openURL(url).catch(err => console.error("Couldn't load page", err));
  };

  return (
    <PaperProvider>
      <View style={[styles.outerContainer, { backgroundColor: theme.neutralBackgroundColor }]}>
        <View style={[styles.toolbar, { backgroundColor: theme.toolbarColor }]}>
          {/* <View style={[{ backgroundColor: theme.secondaryBackgroundColor }]} >
            <TouchableRipple onPress={onMenuPress}>
              <Ionicons
                name={tagListVisible ? "menu" : "menu-outline"}
                size={24}
                color={theme.primaryTextColor}
                style={styles.icon}
              />
            </TouchableRipple>
          </View> */}
          {/* <Tooltip title="Open in FRED"> */}
          <TouchableRipple onPress={openSeriesLink} disabled={!selectedSeries} style={{marginLeft: 22}}>
            <Ionicons
              name={"globe-outline"}
              size={24}
              color={theme.primaryTextColor}
              style={styles.icon}
            />
          </TouchableRipple>
          {/* </Tooltip> */}
          <View style={[styles.pickerContainer]}>
            <Picker
              selectedValue={numYearsToDisplay}
              onValueChange={(itemValue) => setNumYearsToDisplay(itemValue)}
              style={[styles.picker, { backgroundColor: theme.neutralBackgroundColor, color: theme.primaryTextColor, borderWidth: 0 }]}>
              <Picker.Item label="1 Year" value={1} />
              <Picker.Item label="5 Years" value={5} />
              <Picker.Item label="10 Years" value={10} />
              <Picker.Item label="30 Years" value={30} />
            </Picker>
          </View>
          <View style={[{ flex: 1 }]} />
          <View style={[{ display: 'flex', flexDirection: 'row', marginRight: 22, backgroundColor: theme.primaryBackgroundColor }]} >
            <TouchableRipple onPress={selectSeriesListTag}>
              <Ionicons
                name={selectedSeriesList === 'tag' ? "pricetag" : "pricetag-outline"}
                size={24}
                color={theme.primaryTextColor}
                style={styles.icon}
              />
            </TouchableRipple>
            <TouchableRipple onPress={selectSeriesListFavorites}>
              <Ionicons
                name={selectedSeriesList === 'favs' ? "star" : "star-outline"}
                size={24}
                color={theme.primaryTextColor}
                style={styles.icon}
              />
            </TouchableRipple>
          </View>
          <View style={[{ backgroundColor: theme.secondaryBackgroundColor }]} >
            <TouchableRipple onPress={toggleTagList}>
              <Ionicons
                name={tagListVisible ? "pricetags" : "pricetags-outline"}
                size={24}
                color={theme.primaryTextColor}
                style={styles.icon}
              />
            </TouchableRipple>
          </View>
        </View>
        <View style={styles.toolbarShadow} />
        <LargeSeriesScreen />
      </View>
    </PaperProvider>
  );
};

const LargeScreenApp = () => (
  <LargeScreenNavigator />
);

export default LargeScreenApp;

const styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
  },
  toolbar: {
    height: 44,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // paddingHorizontal: 16,
  },
  toolbarShadow: {
    width: '100%',
    height: 2,
    shadowColor: '#000',
    shadowOpacity: 0.2,
    shadowRadius: 2,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)', // For Web
    zIndex: 1,
  },
  icon: {
    // marginRight: 8,
    marginHorizontal: 8,
    padding: 10,
    opacity: 0.7
  },
  pickerContainer: {
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // marginHorizontal: 22,
  },
  picker: {
    height: 44,
    // width: 150,
  },
});