import React, { useState } from 'react';
import { View, Text, StyleSheet, useColorScheme } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { lightTheme, darkTheme } from '../styles/theme';

const MobileNotSupportedScreen = () => {
    const colorScheme = useColorScheme();
    const [theme, setTheme] = useState(colorScheme === 'dark' ? darkTheme : lightTheme);

    return (
        <View style={[styles.container, { backgroundColor: theme.neutralBackgroundColor }]}>
            <Ionicons name="construct" size={64} color={theme.dimTextColor} style={styles.icon} />
            <Text style={[styles.text, { color: theme.primaryTextColor }]}>Mobile device support is coming.</Text>
            <Text style={[styles.text, { color: theme.primaryTextColor }]}>For now, please visit on a tablet or larger.</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        marginBottom: 16,
        // fontWeight: 'light',
    },
    text: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 8,
    },
});

export default MobileNotSupportedScreen;