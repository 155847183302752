// SeriesChartHeader.js
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { globalStyles } from '../styles/globalStyles';

const SeriesChartHeader = ({ theme, selectedSeries, seriesData }) => (
    <View style={styles.header}>
        <View style={styles.headerContent}>
            <Text style={[globalStyles.text, styles.titleText(theme)]}>
                {selectedSeries ? selectedSeries.title : ''}
            </Text>
            <Text style={[globalStyles.text, styles.valueText(theme)]}>
                {
                    selectedSeries && seriesData[selectedSeries.id] &&
                    seriesData[selectedSeries.id]['valueData'][0] &&
                    (
                        Number(seriesData[selectedSeries.id]['valueData'].slice(-1)[0]).toLocaleString()
                    )
                }
            </Text>
        </View>
        <View style={styles.footerContent}>
            <Text style={[globalStyles.text, styles.dimText(theme)]}>
                {selectedSeries ? `${selectedSeries.frequency}: ${selectedSeries.units} (${selectedSeries.seasonal_adjustment_short})` : ''}
            </Text>
            {selectedSeries && seriesData[selectedSeries.id] && (
                <Text style={[globalStyles.text, styles.dimText(theme)]}>
                    {seriesData[selectedSeries.id]['dateData'][0] ? seriesData[selectedSeries.id]['dateData'].slice(-1)[0] : ''}
                </Text>
            )}
        </View>
    </View>

);

const styles = StyleSheet.create({
    header: {
        paddingTop: 10,
        paddingHorizontal: 40,
        paddingBottom: 8,
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'bottom',
    },
    titleText: (theme) => ({
        // flex: 1,
        color: theme.primaryTextColor,
        fontSize: 14,
        paddingTop: 4,
        paddingBottom: 3,
    }),
    valueText: (theme) => ({
        minwidth: 200,
        color: theme.alternateTextColor,
        fontSize: 15,
        fontWeight: 500,
        alignSelf: 'flex-end',
        paddingBottom: 1,
        paddingLeft: 40,
    }),
    footer: {
        paddingHorizontal: 40,
    },
    footerContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dimText: (theme) => ({
        color: theme.dimTextColor,
        fontSize: 12,
    }),
});

export default SeriesChartHeader;