// Loader.js
import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { StyleSheet } from 'react-native';

const Loader = ({ theme }) => (
    <View style={styles.loader(theme)}>
        <ActivityIndicator size="large" color={theme.primaryChartColor} />
    </View>
);

const styles = StyleSheet.create({
    loader: (theme) => ({
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.primaryBackgroundColor,
    }),
});

export default Loader;