import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View, StyleSheet, useColorScheme } from 'react-native';
import { Chip, TouchableRipple } from 'react-native-paper';
import { useSeriesContext } from '../context/SeriesContext';
import { getSeriesListByTag, devLog } from '../utils/utilities.js';
import { lightTheme, darkTheme } from '../styles/theme';
import { globalStyles } from '../styles/globalStyles.js';
import { FlatList, SafeAreaView, Text } from 'react-native';

const TagListView = () => {
    const colorScheme = useColorScheme();
    const theme = useMemo(() => (colorScheme === 'dark' ? darkTheme : lightTheme), [colorScheme]);
    const { selectedTagName, setSelectedTagName, tagList, setSeriesListByTag, fetchSeriesListByTagDataIfNeeded } = useSeriesContext();

    const onTagPress = useCallback(async (tag) => {
        // console.log(`Tag pressed: ${tag.name}`);
        // console.log(`Current selectedTagName: ${selectedTagName}`);
        if (selectedTagName === tag.name) {
            // console.log('Tag is already selected');
            return;
        } else {
            // console.log('Updating selected tag and fetching series list');
            // setSeriesListByTag(null);
            setSelectedTagName(tag.name);
            // const newSeriesList = await getSeriesListByTag(tag.name);
            // setSeriesListByTag(newSeriesList);
            fetchSeriesListByTagDataIfNeeded(tag.name);
        }
    }, [selectedTagName, setSelectedTagName, setSeriesListByTag]);

    useEffect(() => {
        // console.log(`selectedTagName updated: ${selectedTagName}`);
    }, [selectedTagName]);

    const renderTagItem = useCallback(({ item }) => (
        <TouchableRipple
            onPress={() => onTagPress(item)}
            rippleColor="rgba(0, 0, 0, .32)"
        >
            <View style={[styles.itemContainer, {borderBottomColor: theme.secondaryGridColor}]}>
                <Text selectable={true} style={[styles.textContainer, globalStyles.text, {
                    backgroundColor: selectedTagName === item.name ? theme.secondarySelectedBackgroundColor : theme.secondaryBackgroundColor,
                    color: theme.primaryTextColor,
                }]}>
                    {item.name}
                </Text>
            </View>
        </TouchableRipple>
    ), [selectedTagName, theme.primaryTextColor]);

    return (
        <SafeAreaView style={[styles.container, { backgroundColor: theme.secondaryBackgroundColor }]}>
            <FlatList
                data={tagList}
                renderItem={renderTagItem}
                keyExtractor={(item) => item.name}
                extraData={selectedTagName}
            />
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    itemContainer: {
        borderBottomWidth: 2,
        // borderBottomColor: 'gray',
        display: 'flex',
        flexDirection: 'row',
        minHeight: 46,
    },
    textContainer: {
        padding: 12,
        paddingHorizontal: 16,
        userSelect: 'none',
        flex: 1,
    },
    iconContainer: {
        height: '100%',
        padding: 12,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        opacity: 0.5,
    },
});

export default TagListView;