import React, { useMemo, useEffect } from 'react';
import { View, StyleSheet, useColorScheme, Appearance } from 'react-native';
import { useSeriesContext } from '../context/SeriesContext';
import VictoryBarChart from '../components/VictoryBarChart';
import SeriesListView from '../components/SeriesListView';
import TagListView from '../components/TagListView';
import SeriesChartHeader from '../components/SeriesChartHeader';
import Loader from '../components/Loader';
import { lightTheme, darkTheme, setTheme } from '../styles/theme';
import { Text } from 'react-native-paper';
import { globalStyles } from '../styles/globalStyles';
import { TouchableRipple } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons';
import { devLog } from '../utils/utilities';

const LargeSeriesScreen = () => {
    // console.log('LargeSeriesScreen');
    const { selectedSeries, seriesData, seriesListByTagData, setSeriesListByTagData, setTagList, tagListVisible, selectedTagName, selectedSeriesList, favoriteSeriesList, disclaimerVisible, setDisclaimerVisible } = useSeriesContext();
    const jsonData = selectedSeries ? seriesData[selectedSeries.id]?.jsonData : [];
    const colorScheme = useColorScheme();
    const theme = useMemo(() => (colorScheme === 'dark' ? darkTheme : lightTheme), [colorScheme]);


    useEffect(() => {
        // console.log('seriesData updated:', seriesData);
        const subscription = Appearance.addChangeListener(({ colorScheme }) => {
            setTheme(colorScheme === 'dark' ? darkTheme : lightTheme);
        });
        return () => subscription.remove();
    }, [seriesListByTagData, seriesData, favoriteSeriesList]);

    const onDisclaimerCloseClick = () => {
        // console.log('Disclaimer closed');
        setDisclaimerVisible(false);
    }

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                {/* <View style={styles.sideDrawerPlaceholder(theme)} /> */}
                <View style={styles.mainColumn}>
                    <SeriesChartHeader theme={theme} selectedSeries={selectedSeries} seriesData={seriesData} />
                    <View style={styles.chart}>
                        {selectedSeries && <VictoryBarChart data={jsonData} />}
                    </View>
                    <View style={{ flex: 1, overflow: 'scroll', padding: 20 }} >
                        {selectedSeries && (
                            <Text style={[globalStyles.text, { fontSize: 'small', color: theme.dimTextColor }]}>
                                {selectedSeries.notes}
                            </Text>
                        )}
                    </View>
                    {/* ADVERTISEMENT */}
                    {/* {selectedSeries && (
                        <View style={{ height: 90, backgroundColor: 'darkmagenta' }} />
                    )} */}
                    {/* ADVERTISEMENT */}
                    {disclaimerVisible && (
                        <View style={{ height: 'auto', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.secondaryBackgroundColor, padding: 5, display: 'flex', flexDirection: 'row' }}>
                            <TouchableRipple onPress={onDisclaimerCloseClick} style={{ marginRight: 22 }}>
                                <Ionicons
                                    name={"close"}
                                    size={24}
                                    color={theme.dimTextColor}
                                    style={styles.icon}
                                />
                            </TouchableRipple>
                            <View style={{ flex: 1, flexDirection: 'column', marginRight: 82 }}>
                                <Text style={[globalStyles.text, { fontSize: 'small', color: theme.primaryTextColor, textAlign: 'center' }]}>
                                    This product uses the FRED® API but is not endorsed or certified by the Federal Reserve Bank of St. Louis.
                                </Text>
                                {/* <Text style={[globalStyles.text, { fontSize: 'small', color: theme.primaryTextColor, textAlign: 'center' }]}>
                            certified by the Federal Reserve Bank of St. Louis.
                          </Text> */}
                            </View>
                        </View>
                    )}
                </View>
                <View style={styles.verticalShadow} />
                <View style={styles.seriesListColumn}>
                    <View style={styles.item}>
                        {selectedSeriesList === 'tag' ? (
                            <SeriesListView
                                theme={theme}
                                data={seriesListByTagData[selectedTagName]}
                            />
                        ) : selectedSeriesList === 'favs' ? (
                            <SeriesListView
                                theme={theme}
                                data={favoriteSeriesList}
                                emptyMessage={'No favorites yet'}
                            />
                        ) : (
                            <Loader theme={theme} />
                        )}
                    </View>
                </View>
                {tagListVisible ? (
                    <View style={styles.tagListColumn}>
                        <View style={styles.item}>
                            <TagListView />
                        </View>
                    </View>
                ) : (
                    <View style={styles.sideDrawerPlaceholder(theme)} />
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    row: {
        flexDirection: 'row',
        height: '100%',
        flex: 1,
    },
    mainColumn: {
        flex: 5,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // zIndex: 2,
    },
    verticalShadow: {
        height: '100%',
        width: 2,
        shadowColor: '#000',
        shadowOpacity: 0.2,
        shadowRadius: 2,
        boxShadow: '2px 0px 2px rgba(0, 0, 0, 0.2)', // For Web
        zIndex: 1,
    },
    seriesListColumn: {
        flex: 3,
        // zIndex: 3,
    },
    tagListColumn: {
        flex: 0.5,
        minWidth: 150,
    },
    item: {
        flex: 1,
        overflow: 'hidden',
    },
    chart: {
        // flex: 1,
        width: '100%',
        maxHeight: '60vh',
        aspectRatio: 1.5,
        // maxWidth: 750,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    },
    icon: {
        marginHorizontal: 8,
        padding: 10,
        opacity: 0.7
    },
    sideDrawerPlaceholder: (theme) => ({
        backgroundColor: theme.secondaryBackgroundColor,
        width: 8,
        height: '100%',
    }),
});

export default LargeSeriesScreen;