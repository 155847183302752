import React, { useCallback, useMemo } from 'react';
import { FlatList, SafeAreaView, Text, Pressable, View, StyleSheet } from 'react-native';
import { useSeriesContext } from '../context/SeriesContext';
import { globalStyles } from '../styles/globalStyles.js';
import { Ionicons } from '@expo/vector-icons';
import { TouchableRipple } from 'react-native-paper';
import Loader from './Loader';
import { useEffect } from 'react';
// import { logEventWrapper } from '../Firebase.js';
import { logEventWrapper, devLog } from '../utils/utilities.js';
import { useNavigation, useRoute } from '@react-navigation/native'; // Updated imports

const SeriesListView = ({ theme, data, emptyMessage }) => {
  const { selectedSeries, setSelectedSeries, fetchSeriesObservationsIfNeeded, favoriteSeriesList, setFavoriteSeriesList } = useSeriesContext();

  const navigation = useNavigation();
  // const route = useRoute();

  // Log the updated favorite series list
  useEffect(() => {
    // console.log('Favorite series list:', favoriteSeriesList);
  }, [favoriteSeriesList]);

  // Handle item press event
  const onItemPress = useCallback((series) => {
    // console.log('Series pressed:', series);
    if (selectedSeries && selectedSeries.id && selectedSeries.id === series.id) {
      console.log('Already selected:', series.id);
    } else {
      // setSelectedSeries(series);
      navigation.navigate('Series', { id: series.id });
      logEventWrapper('series_selected', { series_id: series.id });
    }
  }, [selectedSeries, setSelectedSeries, fetchSeriesObservationsIfNeeded]);

  // Handle favorite star press event
  const onStarPress = useCallback((series) => {
    // console.log('Star pressed:', series);
    // If series is selected
    if (selectedSeries && selectedSeries.id === series.id) {
      setFavoriteSeriesList(prevList => {
        const isSeriesInList = (prevList && prevList.some(item => item.id === series.id));
        if (isSeriesInList) {
          return prevList.filter(item => item.id !== series.id);
        } else {
          if (!prevList) {
            return [series];
          } else {
            return [...prevList, series];
          }
        }
      });
    } else {
      // console.log('Select the series first:', series.id);
      onItemPress(series);
    }
  }, [selectedSeries, setFavoriteSeriesList]);

  // Render each series item
  const renderSeriesItem = useCallback(({ item }) => (
    <TouchableRipple onPress={() => onItemPress(item)}
      rippleColor="rgba(0, 0, 0, .32)">
      <View style={[styles.itemContainer, { borderBottomColor: theme.gridColor }]}>
        <Text selectable={true} style={[styles.textContainer, globalStyles.text, {
          backgroundColor: selectedSeries && selectedSeries.id === item.id ? theme.selectedBackgroundColor : theme.primaryBackgroundColor,
          color: theme.primaryTextColor,
          fontSize: 'small',
        }]}>
          {item.title}
        </Text>
        {(selectedSeries && selectedSeries.id === item.id) || (favoriteSeriesList && favoriteSeriesList.some(favItem => favItem.id === item.id)) ? (
          <TouchableRipple style={styles.iconContainer} onPress={() => onStarPress(item)}>
            <Ionicons name={(favoriteSeriesList && favoriteSeriesList.some(favItem => favItem.id === item.id)) ? "star" : "star-outline"} size={18} color={theme.primaryTextColor} style={styles.icon} />
          </TouchableRipple>
        ) : null}
      </View>
    </TouchableRipple>
  ), [selectedSeries, theme, onItemPress, favoriteSeriesList]);

  return (
    <SafeAreaView style={[styles.container, { backgroundColor: theme.primaryBackgroundColor }]}>
      {data && data.length > 0 ? (
        <FlatList
          data={data}
          renderItem={renderSeriesItem}
          keyExtractor={(item) => item.id.toString()}
          extraData={[selectedSeries, favoriteSeriesList]}
        />
      ) : (
        emptyMessage ? (
          <Text style={[globalStyles.text, styles.emptyMessage, { color: theme.primaryTextColor }]}>
            {emptyMessage}
          </Text>
        ) : (
          <Loader theme={theme} />
        )
      )}
    </SafeAreaView>
  );
};

// Styles for the component
const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  emptyMessage: {
    // flex: 1,
    padding: 16,
    textAlign: 'center',
  },
  itemContainer: {
    borderBottomWidth: 2,
    display: 'flex',
    flexDirection: 'row',
    minHeight: 46,
  },
  textContainer: {
    padding: 12,
    paddingHorizontal: 16,
    userSelect: 'none',
    flex: 1,
  },
  iconContainer: {
    height: '100%',
    padding: 12,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.5,
  },
  icon: {
    opacity: 0.5,
  },
});

export default SeriesListView;