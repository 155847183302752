// theme.js

// BASE COLOR IS #226666 or #003333

// export const lightTheme = {
//     neutralBackgroundColor: '#ffffff',
//     primaryBackgroundColor: '#ffffff',
//     selectedBackgroundColor: '#226666',
//     primaryChartColor: '#407F7F',
//     primaryTextColor: '#000000',
// };

export const setTheme = (theme) => {
    // Implementation of setTheme
};

export const darkTheme = {
    neutralBackgroundColor: '#001F1F',
    primaryBackgroundColor: '#003333',
    selectedBackgroundColor: '#0D4D4D',
    primaryChartColor: '#407F7F',
    primaryTextColor: '#ffffff',
    alternateTextColor: '#669999',
    dimTextColor: '#808080',
    toolbarColor: '#001F1F',
    secondaryBackgroundColor: '#331700',
    secondarySelectedBackgroundColor: '#552700',
    fontFamily: 'Roboto-Mono',
    gridColor: '#004444',
    secondaryGridColor: '#552700',
};

export const lightTheme = { ...darkTheme };
