
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { devLog } from './utils/utilities';

const firebaseConfig = {
    apiKey: "AIzaSyDIYoy6dfoClorDJb6FAKkjOi28ow-LumI",
    authDomain: "economics-browser.firebaseapp.com",
    databaseURL: "https://economics-browser.firebaseio.com",
    projectId: "economics-browser",
    storageBucket: "economics-browser.appspot.com",
    messagingSenderId: "1008711055099",
    appId: "1:1008711055099:web:9af302a07a09ff9389e25e",
    measurementId: "G-Y6R3KCS7Q6"
    // measurementId: "G-FCBHK3WGK5"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
let analytics;
if (process.env.NODE_ENV !== 'development') {
    analytics = getAnalytics(firebaseApp);
} else {
    console.log('Firebase analytics disabled in development mode');
}

// FUNCTIONS
const functions = getFunctions(firebaseApp);

// UNCOMMENT BELOW TO EMULATE FUNCTION SERVER
// connectFunctionsEmulator(functions, 'localhost', 5001)

const cloudGetFredJson = httpsCallable(functions, 'getFredJson');
export async function getFredJson(urlToGet) {
    // console.log('CLOUD FUNCTION getFredJson: ' + urlToGet);
    if (process.env.NODE_ENV !== 'development') {
        console.log('FUNCTION: DATA PULL');
    } else {
        console.log('CLOUD FUNCTION: getFredJson - url: ' + urlToGet);
    }
    try {
        const jsonData = await cloudGetFredJson({ fredUrl: urlToGet });
        return (jsonData);
        // return null;
    } catch (err) { console.error(err); }
}

export { firebaseApp, analytics };