import React, { useMemo, useEffect, useState } from 'react';
import { View, useColorScheme, Dimensions } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as Font from 'expo-font';
import LargeScreenNavigator from './navigation/LargeScreenNavigator';
import MobileNotSupportedScreen from './screens/MobileNotSupportedScreen';
import { lightTheme, darkTheme } from './styles/theme';
import { AppProvider, useAppContext } from './context/AppContext'; // Import context
import { SeriesProvider } from './context/SeriesContext'; // Import useSeriesContext
import { devLog } from './utils/utilities';

const Stack = createStackNavigator();

const useFonts = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const loadFonts = async () => {
      await Font.loadAsync({
        'Roboto-Mono': require('./assets/fonts/RobotoMono-VariableFont_wght.ttf'),
      });
      setFontsLoaded(true);
    };

    loadFonts();
  }, []);

  return fontsLoaded;
};

const linking = {
  prefixes: ['http://localhost:19006', 'https://economicsbrowser.com'],
  config: {
    screens: {
      Series: 'series/:id',
    },
  },
};

export default function App() {
  // console.log('App');
  const fontsLoaded = useFonts();
  const colorScheme = useColorScheme();
  const theme = useMemo(() => (colorScheme === 'dark' ? darkTheme : lightTheme), [colorScheme]);

  if (!fontsLoaded) {
    return <View />;
  }

  return (
    <AppProvider>
      <SeriesProvider>
        <NavigationContainer linking={linking}>
          <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Series" component={MainScreen} />
          </Stack.Navigator>
        </NavigationContainer>
      </SeriesProvider>
    </AppProvider>
  );
}

const MainScreen = () => {
  const { isLargeScreen } = useAppContext();

  return isLargeScreen ? <LargeScreenNavigator /> : <MobileNotSupportedScreen />;
};
