import React, { useMemo } from 'react';
import { View, ActivityIndicator, useColorScheme } from 'react-native';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer } from 'victory';
import { useSeriesContext } from '../context/SeriesContext';
import { darkTheme, lightTheme } from '../styles/theme'; // Assuming these are defined somewhere
import { globalStyles } from '../styles/globalStyles';
import { devLog } from '../utils/utilities';

const VictoryBarChart = ({ data }) => {
    const colorScheme = useColorScheme();
    const theme = useMemo(() => (colorScheme === 'dark' ? darkTheme : lightTheme), [colorScheme]);
    const { numYearsToDisplay } = useSeriesContext();

    // Calculate the cutoff date
    const cutoffDate = useMemo(() => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - numYearsToDisplay);
        date.setMonth(0); // Set month to January (0-based index)
        date.setDate(1);  // Set date to 1
        // console.log('Cutoff date:', date);
        return date;
    }, [numYearsToDisplay]);

    // Filter the data to include only the last numYearsToDisplay years
    const filteredData = useMemo(() => {
        return data ? data.filter(d => new Date(d.date) >= cutoffDate) : [];
    }, [data, cutoffDate]);

    // Sampling function to reduce data points and ensure the last data point is included
    const sampleData = (data, sampleSize) => {
        const sampledData = [];
        const step = Math.ceil(data.length / sampleSize);
        for (let i = data.length - 1; i >= 0; i -= step) {
            sampledData.push(data[i]);
        }
        return sampledData.reverse(); // Reverse to maintain the original order
    };

    // Sample the filtered data
    const sampledData = useMemo(() => sampleData(filteredData, 180), [filteredData]);

    // Generate tick values for the beginning of each year
    const generateYearTicks = (data) => {
        const years = data.map(d => new Date(d.dateStamp).getFullYear());
        const uniqueYears = Array.from(new Set(years));
        const numYears = uniqueYears.length;
        switch (true) {
            case numYears <= 11:
                return uniqueYears.map(year => new Date(year, 0, 1).getTime());
            case numYears <= 21:
                return uniqueYears.filter((year, index) => index % 2 === 0).map(year => new Date(year, 0, 1).getTime());
            default:
                return uniqueYears.filter((year, index) => index % 3 === 0).map(year => new Date(year, 0, 1).getTime());
        }
    };

    const tickValues = generateYearTicks(sampledData);

    const fontFamily = theme.fontFamily;

    return (
        <View style={{ flex: 1 }}>
        {/* <View style={{}}> */}
            {sampledData.length > 0 ? (
                <VictoryChart
                    containerComponent={<VictoryVoronoiContainer />}
                    padding={{ top: 10, bottom: 30, left: 50, right: 30 }}
                    style={{ flex: 1 }}
                // animate={{ duration: 1000 }}
                >
                    <VictoryAxis
                        tickValues={tickValues}
                        tickFormat={(t) => new Date(t).getFullYear()}
                        style={{
                            orientation: 'bottom',
                            grid: { stroke: theme.gridColor },
                            ticks: { stroke: theme.dimTextColor, size: 5 },
                            tickLabels: { fill: theme.dimTextColor, fontSize: 8, padding: 5, fontFamily }

                        }}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={{
                            grid: { stroke: theme.gridColor },
                            ticks: { stroke: theme.dimTextColor, size: 5 },
                            tickLabels: { fill: theme.dimTextColor, fontSize: 8, padding: 5, fontFamily }
                        }}
                    />
                    <VictoryBar
                        data={sampledData}
                        x="dateStamp"
                        y="value"
                        // labels={({ datum }) => `Date: ${datum.date}\nValue: ${datum.value}`}
                        // labelComponent={<VictoryTooltip
                        //     constrainToVisibleArea />}
                        style={{
                            data: { fill: theme.primaryChartColor },
                            parent: { background: theme.neutralBackgroundColor }
                        }}
                    />
                </VictoryChart>
            ) : (
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: theme.neutralBackgroundColor }}>
                    <ActivityIndicator size="large" color={theme.primaryChartColor} />
                </View>
            )}
        </View>
    );
};

export default VictoryBarChart;