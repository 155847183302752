import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import {
  getSeriesObservations,
  getSeriesListByTag,
  getSingleSeries,
  getTagsByPopularity,
  storeObject,
  readObject,
  devLog,
} from '../utils/utilities.js';
import isEqual from 'lodash.isequal';

const SeriesContext = createContext();
// console.log('SeriesContext');

export const SeriesProvider = ({ children }) => {
  const [seriesListByTagData, setSeriesListByTagData] = useState({});
  const [favoriteSeriesList, setFavoriteSeriesList] = useState(null);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [seriesData, setSeriesData] = useState({});
  const [numYearsToDisplay, setNumYearsToDisplay] = useState(10);
  const [tagListVisible, setTagListVisible] = useState(true);
  const [selectedTagName, setSelectedTagName] = useState('usa');
  const [tagList, setTagList] = useState({});
  const [selectedSeriesList, setSelectedSeriesList] = useState('tag');
  const [lastLocalFavoriteSeriesList, setLastLocalFavoriteSeriesList] = useState(true);
  const [disclaimerVisible, setDisclaimerVisible] = useState(true);

  // Fetch initial data
  useEffect(() => {
    // console.log('Fetching initial data');
    const fetchData = async () => {
      try {
        const [newList, newTags] = await Promise.all([getSeriesListByTag('usa'), getTagsByPopularity()]);
        setSeriesListByTagData(prevData => ({ ...prevData, ['usa']: newList }));
        setTagList(newTags);
        // Read favorite series list from local storage
        const storedFavoriteSeriesList = await readObject('favoriteSeriesList');
        if (storedFavoriteSeriesList) {
          // console.log('Local favorite series list:', storedFavoriteSeriesList);
          setLastLocalFavoriteSeriesList(storedFavoriteSeriesList);
          setFavoriteSeriesList(storedFavoriteSeriesList);
        } else {
          console.log('No favorite series list found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  // On seriesListByTagData change, log it
  useEffect(() => {
    // console.log('Series list by tag data changed:', seriesListByTagData);
  }, [seriesListByTagData]);

  // On favorite series list change, store it locally
  useEffect(() => {
    // console.log('Favorite series list changed');
    const storeFavorites = async () => {
      try {
        await storeObject('favoriteSeriesList', favoriteSeriesList);
        const storedFavoriteSeriesList = await readObject('favoriteSeriesList');
        // console.log('Stored favorite series list:', storedFavoriteSeriesList);
      } catch (error) {
        console.error('Error storing favorite series list:', error);
      }
    };

    if (favoriteSeriesList !== null && !isEqual(lastLocalFavoriteSeriesList, favoriteSeriesList)) {
      // console.log('Storing favorite series list locally:', favoriteSeriesList);
      storeFavorites();
    } else {
      // console.log('Not storing favorite series list');
    }
  }, [favoriteSeriesList]);

  const updateSelectedSeriesById = useCallback(async (id) => {
    // console.log('updateSelectedSeriesById:', id);
    if (seriesListByTagData[selectedTagName]) {
      let newSeries = seriesListByTagData[selectedTagName].find(item => item.id === id)
      // console.log('Series found:', newSeries);
      setSelectedSeries(newSeries);
    } else {
      // console.log('No series list found');
      let newSeries = await getSingleSeries(id);
      setSelectedSeries(newSeries);
    }
    fetchSeriesObservationsIfNeeded(id);
  }, [seriesListByTagData, fetchSeriesObservationsIfNeeded, selectedTagName, setSelectedSeries, setSeriesData, seriesData]);

  const fetchSeriesObservationsIfNeeded = useCallback(async (id) => {
    // console.log('fetchSeriesObservationsIfNeeded:', id);
    // console.log('seriesData:', seriesData);
    if (seriesData[id]) {
      // console.log('Using cached data');
      return;
    }
    try {
      const fredObs = await getSeriesObservations(id);
      setSeriesData(prevData => ({ ...prevData, [id]: fredObs }));
    } catch (error) {
      console.error('Error fetching FRED observations:', error);
    }
  }, [seriesData, setSeriesData, setSelectedSeries]);

  const fetchSeriesListByTagDataIfNeeded = useCallback(async (tagName) => {
    if (seriesListByTagData[tagName]) {
      console.log('Using cached data');
      return;
    }
    try {
      const seriesList = await getSeriesListByTag(tagName);
      setSeriesListByTagData(prevData => ({ ...prevData, [tagName]: seriesList }));
    } catch (error) {
      console.error('Error fetching series list:', error);
    }
  }, [seriesListByTagData]);

  return (
    <SeriesContext.Provider
      value={{
        seriesListByTagData,
        selectedSeries,
        seriesData,
        numYearsToDisplay,
        tagListVisible,
        selectedTagName,
        tagList,
        selectedSeriesList,
        favoriteSeriesList,
        disclaimerVisible,
        setSeriesListByTagData,
        setSelectedSeries,
        setSeriesData,
        setNumYearsToDisplay,
        setTagListVisible,
        setSelectedTagName,
        setTagList,
        setSelectedSeriesList,
        fetchSeriesObservationsIfNeeded,
        fetchSeriesListByTagDataIfNeeded,
        setFavoriteSeriesList,
        updateSelectedSeriesById,
        setDisclaimerVisible,
      }}
    >
      {children}
    </SeriesContext.Provider>
  );
};

export const useSeriesContext = () => useContext(SeriesContext);