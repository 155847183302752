import { StyleSheet } from 'react-native';

export const globalStyles = StyleSheet.create({
  text: {
    fontFamily: 'Roboto-Mono', // Use the font family name from the font file
  },
  axisLabels: {
    fontSize: 8,
    fontFamily: 'Roboto-Mono', // Use the font family name from the font file
  }
});