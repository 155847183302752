import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Firebase from '../Firebase';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../Firebase';

// GENERAL FUNCTIONS
export function formatFredDate(origDate) {
  const tempYear = origDate.slice(0, 4);
  const tempMonth = origDate.slice(5, 7);
  const tempDay = origDate.slice(8, 10);
  const newDateStr = tempMonth + '/' + tempDay + '/' + tempYear;
  const newDate = new Date(newDateStr).toString().slice(0, 15);
  return newDate;
}
export function getDaysAgoDate(daysAgo) {
  const tempDate = new Date();
  const afterDate = new Date(tempDate.getTime());
  afterDate.setDate(tempDate.getDate() - daysAgo);
  // console.log(afterDate);  
  return afterDate;
}

// ASYNC STORAGE - STORE STRING
export const storeString = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (e) { console.error(e) }
}

// ASYNC STORAGE - READ STRING
export const readString = async (key) => {
  try {
    const value = await AsyncStorage.getItem(key);
    return value;
  } catch (e) { console.error(e); }
}

// Utility functions for AsyncStorage
export const readObject = async (key) => {
  try {
    const value = await AsyncStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error('Error reading value from AsyncStorage:', error);
    return null;
  }
};

export const storeObject = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('Error storing value in AsyncStorage:', error);
  }
};

// FRED FUNCTIONS
export async function getSeriesListByTag(tag) {
  const limit = 100;
  const urlToGet = 'https://api.stlouisfed.org/fred/tags/series?tag_names=' + tag + '&order_by=popularity&sort_order=desc&limit=' + limit + '&';
  try {
    const fredJson = await Firebase.getFredJson(urlToGet);
    const newJson = fredJson.data.seriess;
    const newList = [];
    const idList = [];
    newJson.forEach(series => {
      if (series.notes && !series.notes.includes('opyright') && !series.notes.includes('COPYRIGHT')) {
        if (!idList.includes(series.id)) {
          idList.push(series.id);
          series.dateStr = formatFredDate(series.observation_end);
          newList.push(series);
        }
      }
    });
    // console.log(newList);
    return newList;
  } catch (err) { console.error(err) }
}
export async function getSingleSeries(seriesId) {
  const urlToGet = 'https://api.stlouisfed.org/fred/series?series_id=' + seriesId + '&';
  try {
    const fredJson = await Firebase.getFredJson(urlToGet);
    const newJson = fredJson.data.seriess[0];
    return newJson;
  } catch (err) { console.error(err) }
}
export async function getSeriesObservations(seriesId) {
  // console.log(series);
  const limit = 10000;
  let urlToGet = 'https://api.stlouisfed.org/fred/series/observations?series_id=' + seriesId + '&sort_order=desc&limit=' + limit + '&aggregation_method=eop&';
  // console.log('getSeriesObservations');
  try {
    const fredJson = await Firebase.getFredJson(urlToGet);
    const newJson = fredJson.data.observations.reverse();
    // console.log(newJson);
    // const finalData = { jsonData: [], dateData: [], valueData: [], chartData: {} };
    const finalData = { jsonData: [], dateData: [], valueData: [] };
    newJson.forEach(item => {
      if (!Number.isNaN(Number(item.value))) {
        item.value = Number(item.value);
        item.dateStamp = new Date(item.date + 'T00:00:00');
        finalData.jsonData.push(item);
        finalData.dateData.push(item.date);
        finalData.valueData.push(item.value);
      }
    });
    // finalData.chartData = {
    //   labels: finalData.dateData,
    //   datasets: [{
    //     data: finalData.valueData
    //   }]
    // };
    return finalData;
  } catch (err) { console.error(err) }
}
export async function getTagsByPopularity() {
  // console.log('GETTING TAGS');
  const limit = 1000;
  try {
    const urlToGet = 'https://api.stlouisfed.org/fred/tags?order_by=popularity&sort_order=desc&limit=' + limit + '&';
    const fredJson = await Firebase.getFredJson(urlToGet);
    const newJson = fredJson.data.tags;
    const newList = [];
    newJson.forEach(tag => {
      if (!tag.group_id.includes('cc')) {
        newList.push(tag);
      }
    });
    // console.log(newList);
    return newList;
  } catch (err) { console.error(err) }
}

export function logEventWrapper(eventName, eventParams) {
  if (process.env.NODE_ENV === 'development') {
      console.log(`EVENT: ${eventName}`, eventParams);
  } else {
      logEvent(analytics, eventName, eventParams);
  }
}

export function devLog(message, data) {
  if (process.env.NODE_ENV === 'development') {
    if (data !== undefined) {
      console.log(message, data);
    } else {
      console.log(message);
    }
  }
}

// async function GetSeriesByCategory() {
//   const urlToGet = 'https://api.stlouisfed.org/fred/category/series?category_id=32991&';
//   const fredJson = await getSeriesListByTag(urlToGet);
//   // console.log(fredJson);
//   return fredJson;
// }
